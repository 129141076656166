<template>
  <div
    class="relative"
    @click="$emit('click')"
  >
    <div :class="{ 'mb-2 space-y-1': label || subLabel }">
      <label
        v-if="label"
        class="font-medium"
        :for="inputId"
      >
        {{ label }}
      </label>

      <span
        v-if="subLabel"
        class="block text-bb-text-secondary text-sm font-regular"
      >
        {{ subLabel }}
      </span>
    </div>

    <div class="relative">
      <!-- Named slot for the icon -->
      <slot name="prefix" />
      <input
        :id="inputId"
        :type="inputType"
        :name="inputName"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        autocomplete="off"
        :class="[inputWrapperClasses, { 'with-icon': hasIcon }, paddingLeft, paddingRight]"
        class="text-input p-2"
        @input="e => $emit('input', e.target.value)"
        @keydown="emitKeyDown"
        @focus="handleInputFocus"
        @focusout="handleInputFocus"
      />
      <slot name="suffix" />
    </div>

    <feedback-message
      v-if="!hideFeedback"
      :message="errorMessage"
      class="error-message"
    />
  </div>
</template>

<script>
import FeedbackMessage from '@/components/base/FeedbackMessage'

export default {
  name: 'TextInput',
  components: { FeedbackMessage },
  props: {
    inputId: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    paddingLeft: {
      type: String,
      default: '0',
    },
    paddingRight: {
      type: String,
      default: '0',
    },
  },
  emits: ['input', 'focus', 'focusout'],
  data() {
    return {
      hasFocus: false,
    }
  },
  computed: {
    inputWrapperClasses() {
      if (this.disabled) {
        return { disabled: true }
      }
      return {
        focus: this.hasFocus,
        success: this.success,
        error: this.error || !!this.errorMessage,
        paddingLeft: this.paddingLeft,
        paddingRight: this.paddingRight,
      }
    },
  },
  methods: {
    handleInputFocus(e) {
      this.hasFocus = e.type === 'focus'
      this.$emit(e.type, e)
    },
    emitKeyDown(e) {
      this.$emit('keydown', e)
    },
  },
}
</script>

<style scoped lang="scss">
.text-input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  background: white;
  border: $neutral-100 solid 1px;
  border-radius: 8px;
  color: $bb-text-secondary !important;
  position: relative;
  z-index: 0;
}

.focus {
  outline: $bb-brand-purple solid 2px;
  color: $bb-text-default !important;
}

.error {
  border: $bb-error solid 1px;
  &:focus {
    border: $neutral-100 solid 1px;
    outline: $bb-error solid 2px;
  }
}

.error-message {
  position: absolute;
  bottom: -23px;
}
</style>
