<template>
  <div
    class="feedback-message"
    :class="feedbackClasses"
  >
    <transition
      name="fade"
      mode="out-in"
      tag="span"
    >
      <span v-if="message">
        {{ message }}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'feedback-message',
  props: {
    message: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'danger',
      validator: val => ['danger', 'warn', 'info'].includes(val),
    },
  },
  computed: {
    feedbackClasses() {
      return {
        'text-bb-error': this.type === 'danger',
        'text-warn': this.type === 'warn',
        'text-info': this.type === 'info',
      }
    },
  },
}
</script>

<style>
.feedback-message {
  @apply text-xs font-thin;
  min-height: 20px;
}
</style>
